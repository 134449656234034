import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import "./RolesComponent.css";
import { Button } from "react-bootstrap";

const RolesComponent = ({ userDataWithIds, getRowId, columns, handleSave }) => {
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <a href="/logout">Login Again</a>
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
        }}
      >
        <div style={{ height: "75vh", marginBottom: "60px" }}>
          <div style={{ textAlign: "left", paddingTop: "10px" }}>
            <div
              style={{
                marginTop: "10px",
                fontSize: "var(--main-heading)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Role Management
            </div>
            <br />
            <span style={{ fontWeight: 400, fontSize: "15px" }}>
              Permissions - R: Read, &nbsp; W: Write, &nbsp; A: Adimistrator,
              &nbsp; N: None
            </span>
          </div>
          <DataGrid
            style={{
              fontFamily: "Source Sans Pro",
              fontSize: "var(--sub-heading-font-size)",
            }}
            rows={userDataWithIds}
            getRowId={getRowId}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 100,
                },
              },
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            checkboxSelection
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
          />
        </div>
        <div style={{ marginTop: "20px", textAlign: "right" }}>
          <Button
            type="button"
            style={{
              cursor: "pointer",
              backgroundColor: "#0A5F59",
              text: "center",
              padding: "10px 35px",
              border: "1px solid var(--rs-border-primary)",
              position: "static",
            }}
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
};

export default RolesComponent;
